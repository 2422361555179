#planningRecipeDisplay {
    padding-top: 2%;
}

#planningRow {
    display: flex;
    justify-content: space-between;
}


#planningResetPlanButton{
    margin-top: 10px;
    margin-right: 2%
}

@media only screen and (max-width: 767px) {

    #planningPageShoppingList{
        margin-top: 30px;
        border-top: 2px solid rgb(216, 216, 216);
        padding-top: 20px;

    }
    
}

#printListButton{
    margin-left: 5%;
}