@import url('./App.css');

:root{
    --black: #0D0C0A;
    --lightGray: #EEF2E6;
    --darkGray: #39403E;
    --brown: #9C7860;
    --darkMustard: #DBB95B;
    --lightMustard: #FFE184;
    --background: whitesmoke;
  }

.myRecipesDisplayArea {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5%;
    padding: 2% 5%;
    min-height: 100vh;
}

@media only screen and (max-width: 1200px) {
    .myRecipesDisplayArea {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5%;
        padding: 2% 5%;
    }
  }

@media only screen and (max-width: 770px) {
    .myRecipesDisplayArea {
        display: grid;
        grid-template-columns: 1fr;
        gap: 5%;
        padding: 2% 5%;
    }
  }

.my-recipe-card {
    border: 1px solid var(--black);
    padding: 5%;
    border-radius: 5px;
    background-color: #fff;

}

.my-recipe-card h3{
    text-align: center;
    font-size: 1.3em;
}

.my-recipes-card-details{
    margin-top: 2%;
    display: flex;
    justify-content: space-around;
    font-weight: bold;
    font-size: .7em;
    text-transform: uppercase;
}

.recipeCard{
    box-shadow: 5px 5px 5px gray;
}

.recipeCardTitle{
    text-align: center;
}

#recipeSearchBarContainer{
    border-right: 3px solid rgb(214, 214, 214);
    padding: 0% 3%;
    /* min-height: 100vh; */
}

#item-header-search{
    margin:  20% 0% 10% 0%;
}

#recipeListHeader{
    text-align: center;
    margin: 2% 0%;
    /* padding-bottom: 3%; */
    /* border-bottom: 5px solid rgb(214, 214, 214); */
}

#myRecipesListRow{
    max-width: 99vw;
}

#myRecipesSortByDropdown{

}