:root{
  --black: #0D0C0A;
  --lightGray: #EEF2E6;
  --darkGray: #39403E;
  --brown: #9C7860;
  --darkMustard: #DBB95B;
  --lightMustard: #FFE184;
  --background: whitesmoke;
}

body{
  padding:0px;
  margin: 0px;
  background-color: var(--background);
}

/* header{
  height: 170px;
  background-color: var(--brown);
  margin: 0px;
  display: flex;
  justify-content: space-between;
} */

main{
  height: 100%;
  background-color: var(--background);
  padding-bottom: 2%;
  
}

#header-logo {
  width: 120px;
  margin: 20px;
}

.header-links a {
  text-decoration: none;
  color: var(--black);
  text-align: center;
  margin: 0px 15px;
  font-size: 1.5em;

}

.header-links a:hover {
  text-decoration: underline;
  text-underline-offset: 10px;
}

.header-links{
  grid-area: nav;
  display: grid;
  grid-template-columns: repeat(6, auto);
  align-items: center;
  justify-items: center;


}

header button {
  margin: 20px;
}

.addFormSection {
  /* width: 40%; */
  padding: 2%;
  width: 40%;
  margin: 0% 30%
}

#stepsList{
  background-color: var(--darkMustard);
}



.recipeBox {
  border: 2px solid var(--black);
  margin: 2% 30%;
  padding: 2%;
  border-radius: 15px;

}

#recipeDisplayButtons {
  margin: 2%;
}

#recipeDisplayButtons button{
  margin: 2%;
}

#editRecipeForm{
  margin: 2% 30%;
}

.home-cards{
  width: 95%;
  height: 100%;
  box-shadow: 5px 5px 5px gray;
  transition: width .3s;
}

/* .home-cards:hover{
  width: 97%;
  box-shadow: 5px 5px 5px gray;
} */

.home-card-col{
  margin: 2%;
  display: flex;
  justify-content: center;
}

.welcomeHome {
  text-align: center;
  padding: 2%;
}

.nav-links {
  font-size: 1.2em;
}

.nav-links-dropdown{
  font-size: 1.2em;
}

.navBar{
  background-color: var(--background);
  /* border-bottom: 1px solid black; */
  box-shadow: 5px 5px 5px rgb(163, 163, 163);
  margin-bottom: 1%;
}

#my-list-display{
  margin: 2% 30%;
}

#my-list-display h2{
  text-align: center;
  margin: 1em 0em 2em 0em;
}

#addToPantryButton{
  margin: 2% 0%;
}

#pantry-ingredient-list{
  display: flex;
  justify-content: space-between;
}

#pantry-item{
  align-self: center;
}

.row {
  max-width: 99vw;
}